import config from "../../config/api.config";
import ApiService from "../ApiService";

const TicketApi = {
  GetTicket: async(data) => {
    return ApiService.Insert(`${config.apiUrl}/api/v1/checkin/getTicket`,{
      ticketCode: data.ticketCode,
      locationId: data.locationId
    });
  },
  CancelTicket: async(data) => {
    return ApiService.Insert(`${config.apiUrl}/api/v1/checkin/abort`,{
      ticketCode: data.ticketCode,
      locationId: data.locationId,
      reason: data.reason,
      otherReason: data.otherReason 
    });
  }

};

export default TicketApi;