import React, {useEffect, useState } from "react";
import Routes from "./router/Routes";
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import Alert from "./components/alert/Alert";
import LoadingIndicator from "./components/loadingIndicator/loadingIndicator";
import apiConfig from "./config/api.config";

function App() {

  const [backgroundMobile, setBackgroundMobile] = useState("");
  const [backgroundTablet, setBackgroundTablet] = useState("");
  const [backgroundDesktop, setBackgroundDesktop] = useState("");


  import(`./assets/images/${apiConfig.backgroundMobile}`).then((module) => {setBackgroundMobile(module.default)});
  import(`./assets/images/${apiConfig.backgroundTablet}`).then((module) => {setBackgroundTablet(module.default)});
  import(`./assets/images/${apiConfig.backgroundDesktop}`).then((module) => {setBackgroundDesktop(module.default)});

  useEffect(() => {


    const root = document.documentElement;
    root?.style.setProperty(
      "--color-primary",
      apiConfig.colorPrimary
    );
    root?.style.setProperty(
      "--color-secondary",
      apiConfig.colorSecondary
    );
    root?.style.setProperty(
      "--color-tertiary",
      apiConfig.colorTertiary
    );
    root?.style.setProperty(
      "--login-code-background",
      apiConfig.loginCodeBackground
    );
    root?.style.setProperty(
      "--login-code-background-active",
      apiConfig.loginCodeBackgroundActive
    );
    root?.style.setProperty(
      "--login-code-border",
      apiConfig.loginCodeBorder
    );
    root?.style.setProperty(
      "--bcg-desktop",
      `url(${backgroundDesktop})`
    );
    root?.style.setProperty(
      "--bcg-tablet",
      `url(${backgroundTablet})`
    );
    root?.style.setProperty(
      "--bcg-mobile",
      `url(${backgroundMobile})`
    );
  }, [backgroundMobile]);

  return (
    <div className="App">
       <I18nextProvider i18n={i18n}>
            <LoadingIndicator />
            <Alert/>
           <Routes />
       </I18nextProvider>
    </div>
  );
}

export default App;
