import React from "react";
import "./boxesCard.scss";
import {ReactComponent as DotIcon} from '../../assets/images/icon-dot.svg';

const BoxesCard = ({card}) => {
    return (<div className="boxesCard">
        <p className="test-label"> <DotIcon/> <span>{card.label}</span></p>
        <p className="test-value">{card.value}</p>
    </div>)
}

export default BoxesCard;