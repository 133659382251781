import React, { useState, useEffect } from "react";

const SvgIcon = ({ className }) => {
  const [element, setElement] = useState(null);

  useEffect(() => {
    import(`../assets/images/${process.env.REACT_APP_LOGO}`).then((res) => {
      const Icon = res.ReactComponent;
      setElement(<Icon className={className} />);
    });
  }, [className]);

  return element;
};

export default SvgIcon;
