import React, { useState, useEffect } from "react";
import './layout.scss';
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { ReactComponent as ArrowDown} from "../assets/images/icon-arrow-down.svg";
import { useOutsideClick } from "../hook/clickOutside";
import { roles } from "../assets/constants/roles";
import { userActions } from "../store/user-slice";
import LocalStorageService from "../api/LocalStorageService";
import AuthApi from "../api/authApi";
import apiConfig from '../config/api.config';

const AccountSwitch = () => {
    const [showDropdown, setShowDropdown] = useState(false);
    const user = useSelector((store) => store.user.user);
    const activeMenu = useSelector(state => state.user.activeMenu);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const [box, setBox] = useState(null);

    const changeRole = (route,role) => {
        if(blockNavigation()) return; 
        setShowDropdown(false);
        dispatch(userActions.setActiveMenu(role));
        LocalStorageService.set({activeMenu: role});
        navigate(route);
    }

    const logout = async() => {
        if(blockNavigation()) return; 
        const result = await AuthApi.Logout();
        if(result.success){
            LocalStorageService.clear();
            dispatch(userActions.resetValues());
            navigate("/login");
        }
    }

    const handleClickOutside = () => setShowDropdown(false);
    const ref = useOutsideClick(handleClickOutside);

    const blockNavigation = () => {
        if(location.pathname === "/box-status" && box?.forceAccept)
            return true;
        else return false;
    }

    useEffect(() => {
        const localStorageValue= LocalStorageService.get("package");
        if (localStorageValue) {
            let packageValue = null
            try {
                packageValue = JSON.parse(localStorageValue)
            } catch (e) {
                console.error(e)
            }
            if (packageValue) {
                setBox(packageValue)
            }
        }
        // LocalStorageService.get("package") ?? setBox(JSON.parse(LocalStorageService.get("package")));
 }, []);

    return (
        <div className="language-switch" ref={ref}>
             <li className="nav-user" onClick={() => setShowDropdown(!showDropdown)}>Hi, {user?.firstName} <ArrowDown/></li>
            {showDropdown && <div className="language-dropdown account-dropdown">
            <li onClick={() => changeRole("/locations", roles.checkin)} style={{color: activeMenu === roles.checkin && apiConfig.colorPrimary}}>{user?.menu.showCheckin && roles.checkin}</li>
                        <li onClick={() => changeRole("/routes", roles.driver)} style={{color: activeMenu === roles.driver && apiConfig.colorPrimary}}>{user?.menu.showDriver && roles.driver}</li>
                        <li onClick={() => changeRole("/locations", roles.laboratory)} style={{color: activeMenu === roles.laboratory && apiConfig.colorPrimary}}>{user?.menu.showLaboratory && roles.laboratory}</li>
                          <li className="nav-logout" onClick={() => logout()}>Logout</li>
    
            </div>}

        </div>
    )
}

export default AccountSwitch;