import React, {useState, useEffect} from "react";
import OutlinedButton from "../buttons/OutlinedButton";
import TicketApi from "../../api/checkin/ticketApi";
import LocalStorageService from "../../api/LocalStorageService";
import { alertActions } from "../../store/alert-slice";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import './nothingCorrect.scss';
import ErrorModal from "../modal/errorModal";
import { useNavigate } from "react-router-dom";

const NothingCorrect = ({ticketId}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const route = useSelector((store) => store.user.route);
    const [open, setOpen] = useState(false);
    const [ticket, setTicket] = useState(null);
    const [reason, setReason] = useState("");
    const [t] = useTranslation();

    const CancelTicket = async (data) => {
       await TicketApi.CancelTicket({
            ticketCode: ticketId,
            locationId:route,
            reason: data.cancelReason,
            otherReason: reason ?? null
        })
        .then(res => {
             dispatch(alertActions.addAlert({msg:"Ticket successfully aborted.", type: "success"}));
             navigate("/dashboard");
            })
        .catch(err => dispatch(alertActions.addAlert({msg:err?.data?.error.message, type: "error"})))
    }

    useEffect(() => {
          setTicket(JSON.parse(LocalStorageService.get("ticket") ?? ""));
    }, []);

    return (<>
            <OutlinedButton onClick={() =>setOpen(true)}>{t("nothing_correct.nothing_correct")}</OutlinedButton>
           <ErrorModal 
           title={t("nothing_correct.nothing_correct")}
           open = {open}
           setOpen = {setOpen}
           CancelTicket={CancelTicket}
           data={ticket?.abortCheckinReasons}
           reason = {reason}
           setReason = {setReason}
           />   
    </>)

}

export default NothingCorrect;