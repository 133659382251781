import React from "react";
import { useSelector } from "react-redux";
import './loadingIndicator.scss';

const LoadingIndicator  = () => {
    const loadingState = useSelector((state) => state.loadingIndicator);

    return (
        <div className={`loadingIndicator ${loadingState.activeLoading ? 'active' : ''}`}>
            <div className="loadingIndicatorBackground">
                <div className="loadingIndicatorIcon">
                    <div className="lds-roller">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoadingIndicator;