import {ReactComponent as DotIcon} from '../../assets/images/icon-dot.svg';
import "./listItem.scss";

const ListItem = ({prop, children}) => (<div className="list-item">
    <div>
        <p><DotIcon/> <span>{prop.label}</span></p>
        <p>{prop.value}</p>
    </div>
    {children}
</div>)
export default ListItem;