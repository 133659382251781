import config from "../../config/api.config";
import ApiService from "../ApiService";
const ContainerApi = {
  GetContainer: async(data) => {
    return ApiService.Insert(`${config.apiUrl}/api/v1/driver/getContainer`,{
     containerCode: data.containerCode
    });
  },
  PickUpContainer: async(data) => {
    return ApiService.Insert(`${config.apiUrl}/api/v1/driver/pickUpContainer`,{
     containerCode: data.containerCode,
     routeId:data.locationId,
     status: data.status,
     note: data.note
    });
  }
};

export default ContainerApi;