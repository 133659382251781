import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

const initialState = {
    messages: []
};

const addAlert = (state, action) => {
    state.messages = [...state.messages, {...action.payload, id:uuidv4()}];
 }
 const deleteAlert = (state, { payload })  => {
    state.messages = state.messages.filter((alert) => alert.id !== payload);
 }

const alertSlice = createSlice({
   name: "alert",
   initialState,
   reducers: {
      addAlert,
      deleteAlert
   }
});

export const alertActions = alertSlice.actions;

export default alertSlice;