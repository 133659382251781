import axios from "axios";
import LocalStorageService from "./LocalStorageService";
import apiConfig from "../config//api.config";
import i18n from 'i18next';

let calledOnce = false;

axios.interceptors.request.use(
  (config) => {
    config.headers["Accept-Language"]= i18n.language;
    config.headers["X-TENANT-ID"] = apiConfig.tenantId;
    if (config.url !== `${apiConfig.apiUrl}/api/v1/login`) {
      const token = LocalStorageService.getAccessToken();
      if (token) {
        config.headers["Authorization"] = "Bearer " + token;
      }
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    const originalRequest = error.config;

    if (error.response?.status === 401) {
      return Promise.reject(error);
    }

    if (
      error.response?.status === 401 &&
      !originalRequest._retry &&
      !calledOnce
    ) {
      originalRequest._retry = true;
      calledOnce = true;
    }
    return Promise.reject(error);
  }
);