import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    ticket:null
};

const setTicket = (state, action) => {
    state.ticket = action.payload;
 }
 const getTicket = (state)  => {
   return state.ticket;
 }

const ticketSlice = createSlice({
   name: "ticket",
   initialState,
   reducers: {
      setTicket,
      getTicket
   }
});

export const ticketActions = ticketSlice.actions;

export default ticketSlice;