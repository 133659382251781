import React, {useState} from "react";
import Modal from "./modal";
import "./modal.scss";
import Button from "../buttons/Button";
import Input from "../inputs/Input";
import ContainerApi from "../../api/lab/containerApi";
import ContainerCheckinApi from "../../api/checkin/containerApi";
import ContainerApiDriver from "../../api/driver/containerApi";
import { alertActions } from "../../store/alert-slice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { roles } from "../../assets/constants/roles";

const SetStatusModal = ({open, setOpen, data, containerCode, setBlockNavigation}) => {
const dispatch = useDispatch();
const navigate = useNavigate();
const [t] = useTranslation();
const [note, setNote] = useState("");
const [status, setStatus] = useState([]);
const activeMenu = useSelector(state => state.user.activeMenu);
const route = useSelector((store) => store.user.route);

    const onClick = async () => {
        setBlockNavigation(false);

            await ContainerApi.AcceptContainer({
                containerCode: containerCode, 
                locationId: route,
                status: status,
                note: note
              })
              .then(res => {
                if(res.success){
                   dispatch(alertActions.addAlert({msg:t("container.successfully_accept_package"), type: "success"}))
                    navigate("/dashboard");
                }
               })
               .catch(err => dispatch(alertActions.addAlert({msg:err?.data?.error.message, type: "error"})))
    }

    const onClickCheckin = async () => {
        setBlockNavigation(false);

        await ContainerCheckinApi.ReceiveContainer({
            containerCode: containerCode, 
            locationId: route,
            status: status,
            note: note
          })
          .then(res => {
            if(res.success){
               dispatch(alertActions.addAlert({msg:t("container.successfully_accept_package"), type: "success"}))
                navigate("/dashboard");
            }
           })
           .catch(err => dispatch(alertActions.addAlert({msg:err?.data?.error.message, type: "error"})))
}

const onClickPickUp = async() => {
    setBlockNavigation(false);

    await ContainerApiDriver.PickUpContainer({
        locationId: route,
        containerCode: containerCode,
        status: status,
        note: note
    })
    .then(res => {
     if(res.success){
        dispatch(alertActions.addAlert({msg:t("sample.successfully_pick_up"), type: "success"}))
         navigate("/dashboard");
     }
    })
    .catch(err => dispatch(alertActions.addAlert({msg:err?.data?.error.message, type: "error"})))
}

    const onCheckboxChange = (e) => {
        if(status.includes(e.target.value))
        setStatus(status.filter(s => s !== e.target.value))
        else
        setStatus([...status, e.target.value])
    }

        return(
            <Modal
            open={open}
            setClose={() => setOpen(false)}
            className="set-status-modal"
            >
                  <h4>Define the status</h4>
                  <p>You can select multiple options</p>
                {data?.map(_status =><div key={_status.key} className="set-status-value">
                    <input type="checkbox"
                    checked={status.includes(_status.key)}
                     id={_status.key} value={_status.key} onChange={onCheckboxChange}/>
                    <label htmlFor={_status.key}> {_status.value}</label><br/>
                </div> )}
               
               <p className="input-title">Leave a note <span>(optional)</span></p>
                <Input
                    label=""
                    placeholder="Write something"
                    type="text"
                    value={note}
                    onChange={e => setNote(e.target.value)}
            />
             <Button 
             style={{margin: "0 auto"}}
             onClick={activeMenu === roles.driver? onClickPickUp : activeMenu === roles.checkin? onClickCheckin : onClick}>Accept package</Button>
                </Modal>
        )
}

export default SetStatusModal;