import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    layout:null
};

const setLayout = (state, action) => {
    state.layout = action.payload;
 }
 const getLayout = (state)  => {
   return state.layout;
 }

const layoutSlice = createSlice({
   name: "layout",
   initialState,
   reducers: {
      setLayout,
      getLayout
   }
});

export const layoutActions = layoutSlice.actions;

export default layoutSlice;