import React, {useEffect} from "react";
import { useDispatch } from "react-redux";
import { layoutActions } from "../../store/layout-slice";
import "./settings.scss";
import { useTranslation } from 'react-i18next';
import { userActions } from "../../store/user-slice";
import WhiteButton from "../../components/buttons/WhiteButton";
import { useNavigate } from "react-router-dom";
import AuthApi from "../../api/authApi";
import LocalStorageService from "../../api/LocalStorageService";

const Settings = () => {
    const { i18n, t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const changeLanguage = (lang) => {
        i18n.changeLanguage(lang);
    }

    const changeRole = (role) => {
        dispatch(userActions.setActiveRole(role));
    }

    const logout = async() => {
        const result = await AuthApi.Logout();
        if(result.success){
            LocalStorageService.clear();
            dispatch(userActions.resetValues());
            navigate("/login");
        }

    }

    useEffect(() => {
        dispatch(layoutActions.setLayout("settings.settings"))
    }, []);

    return (
        <div className="settings-wrapper">
            <div className="settings">
                <div className="setting-section">
                    <p>{t("settings.languages")}</p>
                    <div className="setting-buttons">
                        <button className={`setting-option ${i18n.language === "en" && "setting-option--active"}`} onClick={() => changeLanguage('en')}>En</button>
                        <button className={`setting-option ${i18n.language === "de" && "setting-option--active"}`} onClick={() => changeLanguage('de')}>De</button>
                    </div>

                </div>
                {/* <div className="setting-section">
                    <p>{t("settings.roles")}</p>
                    <div className="setting-buttons">
                        {user?.roles?.map(_role =>  <button key={_role.value} className={`setting-option ${role === _role.value && "setting-option--active"}`} onClick={() => changeRole(_role.value)}>{_role.label}</button> )}      
                    </div>
                </div> */}
            </div>
        <WhiteButton
        onClick={() => logout()}
        style={{
            borderRadius: "10px",
            color:"#E60000",
            textAlign:"left",
            paddingLeft:"25px"
        }}
        >Logout</WhiteButton>
        </div>
    )
}

export default Settings;