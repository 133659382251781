import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import LocalStorageService from "../api/LocalStorageService";

const PrivateOutlet = () => {
   return (
      <>
         { LocalStorageService.getAccessToken() ? (
            <Outlet />
         ) : (
            <Navigate to="/login" />
         )}
      </>
   );
};

export default PrivateOutlet;