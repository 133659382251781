import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    activeStep: 0,
    nextActiveStep: -1,
    completed: {},
    secondFormTouched: false
}

const handleNext = (state) => {
    state.activeStep = state.activeStep + 1;
  };

  const handleBack = (state, action) => {
    if (state.secondFormTouched) {
      state.nextActiveStep = -1;
    } else {
        loginStepsSlice.caseReducers.updateActiveStep(state, {
        ...action,
        payload: state.activeStep - 1,
      });
    }
  };

  const updateActiveStep = (
    state,
    action
  ) => {
    if (action.payload === 0) {
        loginStepsSlice.caseReducers.removeCompletedActionStep(state, {
        ...action,
        payload: 0,
      });
    }
    state.activeStep = action.payload;
  };

  const removeCompletedActionStep= (
    state,
    action
  ) => {
    if (state.completed[action.payload]) {
      delete state.completed[action.payload];
    }
  };

const loginStepsSlice = createSlice({
    name: "loginSteps",
    initialState,
    reducers: {
        handleNext,
        handleBack,
        updateActiveStep,
        removeCompletedActionStep
    },
  });
  
  export const loginStepsActions = loginStepsSlice.actions;
  export default loginStepsSlice;