import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LocalStorageService from "../../api/LocalStorageService";
import { layoutActions } from "../../store/layout-slice";
import ListItem from "../../components/listItem/listItem";
import Button from "../../components/buttons/Button";
import OutlinedButton from "../../components/buttons/OutlinedButton";
import Scanner from "../../components/scanner/Scanner";
import SampleApi from "../../api/checkin/sampleApi";
import ContainerApi from "../../api/checkin/containerApi";
import ContainerApiDriver from "../../api/driver/containerApi";
import { sampleActions } from "../../store/sample-slice";
import { alertActions } from "../../store/alert-slice";
import {Html5Qrcode} from "html5-qrcode"
import { useTranslation } from "react-i18next";
import { roles } from "../../assets/constants/roles";
import {ReactComponent as EditIcon} from "../../assets/images/icon-edit.svg";
import SetStatusModal from "../../components/modal/setStatusModal";


import Modal from "../../components/modal/modal";
import { useCallbackPrompt } from "../../hook/useCallbackPropmpt";

const BoxStatus = () => {
    const navigate = useNavigate();
    const [t] = useTranslation();
    const data = useSelector(state => state.sample.package);
    const _sample = useSelector(state => state.sample.sample);
    const route = useSelector((store) => store.user.route);
    const dispatch = useDispatch();
    const [box, setBox] = useState(null);
    const [buttonType, setButtonType] = useState(null);
    const [html5QrCode, setHtml5QrCode] = useState();
    const [sample, setSample] = useState(null);
    const activeMenu = useSelector(state => state.user.activeMenu);
    const dashboard = useSelector((store) => store.user.dashboard);
    const [statusModal, setStatusModal] = useState(false);
    const [blockNavigation, setBlockNavigation] = useState(false);
    const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(blockNavigation)

    useEffect(() => {
        if(data) setBox(data);
        else setBox(JSON.parse(LocalStorageService.get("package") ?? ""));

        const sample = LocalStorageService.get("sample");
        if(_sample) setSample(_sample);
        else  if(sample) setSample(JSON.parse(sample));

        setHtml5QrCode(new Html5Qrcode( "reader"))
    }, []);


    useEffect(() => {
        dispatch(layoutActions.setLayout(activeMenu === roles.laboratory? box?.container?.containerCode : "sample.box_status"));
        setBlockNavigation(box?.forceAccept);
    }, [box])

    const GetPackage = async (ticketId) => {
        await ContainerApi.GetContainer({
            locationId: route,
            containerCode: ticketId
        })
        .then(res => {
            setButtonType(null);
            dispatch(sampleActions.setPackage(res));
            LocalStorageService.set({package:JSON.stringify(res)})
            navigate("/box-status");
        })
        .catch(err => dispatch(alertActions.addAlert({msg:err?.data?.error.message, type: "error"})))
    }

    const GetPackageDriver = async (ticketId) => {
        await ContainerApiDriver.GetContainer({
            containerCode: ticketId
        })
        .then(res => {
            setButtonType(null);
            dispatch(sampleActions.setPackage(res));
            LocalStorageService.set({package:JSON.stringify(res)})
            navigate("/box-status");
        })
        .catch(err => dispatch(alertActions.addAlert({msg:err?.data?.error.message, type: "error"})))
    }

    useEffect(() => {
        setHtml5QrCode(new Html5Qrcode( "reader"))
}, [])

    const PackSample = async() => {
        await SampleApi.PackSample({
            locationId: route,
            containerCode: box?.container.containerCode,
            sampleCode: sample?.sample.sampleId
        })
        .then(res => {
         if(res.success){
            dispatch(alertActions.addAlert({msg:t("sample.successfully_added_test", {value: box?.container.containerCode}), type: "success"}))
             navigate("/dashboard");
         }
        })
        .catch(err => dispatch(alertActions.addAlert({msg:err?.data?.error.message, type: "error"})))
    }

    return (
        <div className="box-status-wrapper">
            <div className="sample dashboard">
            {box?.containerProperties?.map((prop, index) =>  <ListItem prop={prop} key={index}/>)}
            {box?.status?.show && <ListItem prop={{
                label:"Box status",
                value:box?.status?.value
            }}>
                <EditIcon onClick={() => setStatusModal(true)}/>
            </ListItem>}

            {box?.samples.length > 0 && <>
                <h4>Samples</h4>
                    <div className="dashboard-samples">
                        {box?.samples?.map((sample,index) => <div key={index} className="samples-card" >
                            <div className="card-top">{sample.sampleCode} <span className={`test-type ${sample.sampleType}`}>{sample.sampleTypeLabel}</span></div>
                                {sample.properties.map((prop, index) =>   <div className="card-bottom" key={index}>
                                <label className="label">{prop.label}</label> <label className="value">{prop.value}</label>
                            </div>)}
                        </div>)}
                    </div>
                    </>}
           </div>
           <div className="sample-buttons">
           {activeMenu === roles.checkin && LocalStorageService.get("emptyBox") === "false" &&  <Button onClick={() => PackSample()}>Continue</Button>}
           {activeMenu === roles.checkin && LocalStorageService.get("emptyBox") === "true" &&  <Button onClick={() => box?.forceAccept ?  setStatusModal(true) : navigate('/dashboard')}>{box?.proceedButtonLabel}</Button>}
           {activeMenu === roles.driver && <Button onClick={() => setStatusModal(true)}>Pick up</Button>}
           {activeMenu === roles.laboratory && <Button onClick={() => box?.forceAccept ?  setStatusModal(true) : navigate('/dashboard')}>{box?.proceedButtonLabel}</Button>}
           {activeMenu !== roles.laboratory && LocalStorageService.get("emptyBox") === "false" && <OutlinedButton onClick={() => setButtonType("box_packing")}>Scan again</OutlinedButton>}
        </div>
        <Scanner 
              setButtonType={setButtonType}
             buttonType={buttonType}
             html5QrCode={html5QrCode} getTicket={buttonType === "box_packing"? (activeMenu === roles.checkin? GetPackage : activeMenu === roles.driver? GetPackageDriver : null):  null}
             boxScanbar={buttonType === "box_packing"? dashboard?.scanBar : null}/>

             <SetStatusModal
             open={statusModal}
             setOpen={setStatusModal}
             data={box?.statusSelection}
             containerCode={box?.container?.containerCode}
             setBlockNavigation={setBlockNavigation}/>

             <Modal
              className="sample-set-status-modal"
             open={showPrompt}
             setClose={cancelNavigation}>
            <p>You have to accept the package</p>
                <div className="set-status-buttons">
                    <Button onClick={cancelNavigation}>OK</Button>
                </div>
             </Modal>
        </div>
    )
}

export default BoxStatus;