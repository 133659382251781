import config from "../../config/api.config";
import ApiService from "../ApiService";
const SampleApi = {
  PackSample: async(data) => {
    return ApiService.Insert(`${config.apiUrl}/api/v1/lab/packSample`,{
      sampleCode: data.sampleCode,
      containerCode: data.containerCode,  
      locationId: data.locationId
    });
  },
  CreateSubsample: async(data) => {
    return ApiService.Insert(`${config.apiUrl}/api/v1/lab/createSubSample`,{
      sampleCode: data.sampleCode,
      subSampleCode: data.subSampleCode,  
      locationId: data.locationId
    });
  },
};

export default SampleApi;