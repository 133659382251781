import React from "react";
import "./modal.scss"

const Modal = ({open, setClose, className, children}) => {

    return (
        <div className="modal-wrapper" style={{display: open? "block" : "none"}}>
            <div className="modal-background" onClick={setClose}></div>
            <div className={`${className} modal`}>
                {children}
            </div>
        </div>
    )

}

export default Modal;