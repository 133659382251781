import React from "react";
import { useDispatch, useSelector } from "react-redux";
import './login.scss';
import Background from '../../assets/images/login-background.png';
import FirstStep from "./FirstStep";
import SecondStep from "./SecondStep";
import { loginStepsActions } from "../../store/login-steps-slice";
import SvgIcon from "../../helper/SVGIcon";

const LoginPage = () => {
    const data = useSelector((store) => store.loginStep);
    const dispatch = useDispatch();

    const onStepButtonClick = (index) =>  dispatch(loginStepsActions.updateActiveStep(index));


    const getStepContent = (stepIndex) => {
        switch (stepIndex) {
          case 0:
            return <FirstStep onChangeStep={onStepButtonClick}/>;
          case 1:
            return <SecondStep onChangeStep={onStepButtonClick}/>;
          default:
            return "Unknown stepIndex";
        }
      };

    return (
        <div className="login">
                <img src={Background} className="login-img"/>
            <div className="login-wrapper">
                <SvgIcon className="logo"/>
                {getStepContent(data.activeStep)}
            </div>

            
        </div>
    )

}

export default LoginPage;