import React, {useEffect, useState} from "react";
import './dashboard.scss';
import {Html5Qrcode} from "html5-qrcode"
import Scanner from "../../components/scanner/Scanner";
import TicketApi from "../../api/checkin/ticketApi";
import SampleApi from "../../api/checkin/sampleApi";
import LocalStorageService from "../../api/LocalStorageService";
import { alertActions } from "../../store/alert-slice";
import { ticketActions } from "../../store/ticket-slice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {ReactComponent as PersonIcon} from '../../assets/images/icon-person.svg';
import WhiteButton from "../../components/buttons/WhiteButton";
import "react-circular-progressbar/dist/styles.css";
import { sampleActions } from "../../store/sample-slice";
import moment from "moment/moment";
import OverviewChart from "../../components/overviewChart/overviewChart";
import BoxesCard from "../../components/boxesCard/boxesCard";
import ContainerApi from "../../api/checkin/containerApi";


const CheckinDashboard = () => {
    const current_date = moment().format("MM/DD/YYYY");
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [t] = useTranslation();
    const dashboard = useSelector((store) => store.user.dashboard);
    const route = useSelector((store) => store.user.route);
    const [html5QrCode, setHtml5QrCode] = useState();
    const [sampleScanbar, setSampleScanbar] = useState(null);
    const [buttonType, setButtonType] = useState(null);

    const GetTicket = async (ticketCode) => {
        await TicketApi.GetTicket({
            ticketCode: ticketCode,
            locationId: route
        })
            .then(res => {
                dispatch(ticketActions.setTicket(res));
                LocalStorageService.set({ticket:JSON.stringify(res)})
                navigate("/booking");

            })
            .catch(err => dispatch(alertActions.addAlert({msg:err?.data?.error.message, type: "error"})))

    }

    const GetSampleScanbar = async() => {
        await SampleApi.GetSampleScanbar({
            locationId: route
        })
        .then(res => setSampleScanbar(res))
        .catch(err => dispatch(alertActions.addAlert({msg:err?.data?.error.message, type: "error"})))
    }

    const GetSample = async (ticketId) => {
        await SampleApi.GetSample({
            locationId: route,
            sampleCode: ticketId
        })
        .then(res => {
            dispatch(sampleActions.setSample(res));
            LocalStorageService.set({sample:JSON.stringify(res)})
            navigate("/sample");

        })
        .catch(err => dispatch(alertActions.addAlert({msg:err?.data?.error.message, type: "error"})))
    }

    const QuickCreateSample = async(ticketId) => {
        await SampleApi.CreateSample({
            locationId: route,
            sampleCode: ticketId
        })
            .then(async res => {
                dispatch(sampleActions.setSample(res));
                LocalStorageService.set({sample:JSON.stringify(res)})

                await SampleApi.GetSample({
                    locationId: route,
                    sampleCode: ticketId
                })
                .then(res => {
                    dispatch(sampleActions.setSample(res));
                    LocalStorageService.set({sample:JSON.stringify(res)})
                    navigate("/sample");
                })
                .catch(err => dispatch(alertActions.addAlert({msg:err?.data?.error.message, type: "error"})))

            })
            .catch(err => dispatch(alertActions.addAlert({msg:err?.data?.error.message, type: "error"})))
    }

    const GetPackage = async (ticketId) => {
        await ContainerApi.GetContainer({
            locationId: route,
            containerCode: ticketId
        })
        .then(res => {
            setButtonType(null);
            dispatch(sampleActions.setPackage(res));
            LocalStorageService.set({package:JSON.stringify(res)})
            navigate("/box-status");
        })
        .catch(err => dispatch(alertActions.addAlert({msg:err?.data?.error.message, type: "error"})))
    }

    useEffect(() => {
            setHtml5QrCode(new Html5Qrcode( "reader"))
        route && GetSampleScanbar();
    }, [route])

    return (
            <div className="dashboard">
                {dashboard?.dashboardView?.showOverviewChart ?
                    <OverviewChart overviewChart={dashboard?.overviewChart}/>
                    :null
                }

                <div className="dashboard-buttons">
                    { dashboard?.dashboardActions?.scanTicket ? <WhiteButton onClick={() => setButtonType("check")}>{t("dashboard.check")}</WhiteButton> : null }
                    { dashboard?.dashboardActions?.scanSample ? <WhiteButton style={{marginTop:"20px"}} onClick={() => {setButtonType("scane_probe"); LocalStorageService.set({emptyBox: false})}}>{t("dashboard.scane_probe")}</WhiteButton> : null }
                    { dashboard?.dashboardActions?.scanEmptyBox ? <WhiteButton style={{marginTop:"20px"}} onClick={() => {setButtonType("scan_empty_box"); LocalStorageService.set({emptyBox: true})}}>{t("dashboard.scan_empty_box")}</WhiteButton> : null }
                    { dashboard?.dashboardActions?.quickCreateSample ? <WhiteButton style={{marginTop:"20px"}} onClick={() => {setButtonType("quick_create_sample"); LocalStorageService.set({emptyBox: true})}}>{t("dashboard.quick_create_sample")}</WhiteButton> : null }

                    <Scanner
                        buttonType={buttonType}
                        setButtonType={setButtonType}
                        html5QrCode={html5QrCode}
                        getTicket={
                            buttonType === "check" ? GetTicket :
                            buttonType === "scane_probe" ? GetSample :
                            buttonType === "scan_empty_box" ? GetPackage :
                            buttonType === "quick_create_sample" ? QuickCreateSample :
                            null
                        }
                        boxScanbar={
                            buttonType === "scane_probe" ? sampleScanbar :
                            buttonType === "check" ? dashboard?.scanBar :
                            buttonType === "scan_empty_box" ? dashboard?.scanBar :
                            buttonType === "quick_create_sample" ? dashboard?.quickCreateScanBar :
                            null
                        }
                        />
                </div>

                {dashboard?.dashboardView?.showSchedule ?
                    <div className="dashboard-group">
                        <h4>{t("dashboard.schedule")}</h4>
                        <p>{t("dashboard.schedule_next")}</p>
                        <div className="dashboard-schedule">
                            {dashboard?.schedule?.map((test, index) => <div className="schedule-card" key={index} style={{opacity: moment(`${current_date} ${test.timeslot}`).isAfter(moment().format("MM/D/YYYY hh:mm")) && "0.5"}}>
                                <div className="card-top">{test.timeslot}</div>
                                <div className="card-bottom"> <PersonIcon/> <label className="label">{test.amount}</label> </div>
                            </div>)}
                        </div>
                    </div>
                : null}

                {dashboard?.dashboardView?.showOpenSamples || dashboard?.dashboardView?.showFinishedResults ?
                    <div className="dashboard-group">
                    <h4>{t("dashboard.samples")}</h4>

                    {dashboard?.dashboardView?.showOpenSamples ?
                        <div className="dashboard-group">
                        <p>{t("dashboard.samples_open_tests")}</p>
                        <div className="dashboard-samples">
                            {dashboard?.openTests?.map((test,index) => <div key={index} className="samples-card"
                            style={{borderLeft: test.flag === "warn" && "7px solid #E60000"}}
                            onClick={() => GetSample(test.code)}
                            >
                                <div className="card-top">{test.code} <span className={`test-type ${test.type}`}>{test.typeLabel}</span></div>
                                    {test.propertyList.map((prop, index) =>   <div className="card-bottom" key={index}>
                                    <label className="label">{prop.label}</label> <label className="value" style={{color: prop.flag === "warn" && "#E60000"}}>{prop.value}</label>
                                </div>
                                        )}
                            </div>)}
                        </div>
                        </div>
                    : null}

                    {dashboard?.dashboardView?.showFinishedResults ?
                        <div className="dashboard-group">
                            <p>{t("dashboard.samples_finish_tests")}</p>
                            <div className="dashboard-finished">
                               {dashboard?.finishedTests?.map((test, index) => <BoxesCard key={index} card={test}/>)}
                            </div>
                        </div>
                    : null}

                    </div>
                : null}
            </div>
        )

}

export default CheckinDashboard;