import config from "../../config/api.config";
import ApiService from "../ApiService";
const ContainerApi = {
  GetContainer: async(data) => {
    return ApiService.Insert(`${config.apiUrl}/api/v1/lab/getContainer`,{
     containerCode: data.containerCode,
     locationId: data.locationId
    });
  },
  AcceptContainer: async(data) => {
    return ApiService.Insert(`${config.apiUrl}/api/v1/lab/acceptContainer`,{
      containerCode: data.containerCode,  
      locationId: data.locationId,
      status: data.status,
      note: data.note
     });
  }
};

export default ContainerApi;