import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    user:null,
    dashboard:null,
    route: null,
    routeName: '',
    activeMenu:null,
    routes:[],
    routeLabel:''
};

const setUser = (state, action) => {
    state.user = action.payload;
 }
 const getUserType = (state)  => {
   return state.user.access;
 }

 const setDashboard = (state, action) => {
   state.dashboard = action.payload;
}

const setRoute = (state, action) => {
   state.route = action.payload === null? null : action.payload.value;
   state.routeName = action.payload === null? "" : action.payload.label;
}

const setRoutes = (state, action) => {
   state.routes = action.payload;
}

const setRouteLabel = (state, action) => {
   state.routeLabel = action.payload;
}


const resetValues = (state) => {
   state = {
      user:null,
      dashboard:null,
      role:null,
      location:null,
      route: null,
      routeName: '',
      activeMenu:null,
      routes:[],
      routeLabel:''
  };
}

const setActiveMenu =  (state, action) => {
   state.activeMenu = action.payload;
}

const userSlice = createSlice({
   name: "user",
   initialState,
   reducers: {
      setUser,
      getUserType,
      setDashboard,
      setRoute,
      resetValues,
      setActiveMenu,
      setRoutes,
      setRouteLabel
   }
});

export const userActions = userSlice.actions;

export default userSlice;