import React, { useEffect, useState } from "react";
import './layout.scss';
import { Outlet, useNavigate } from "react-router-dom";
import AuthApi from "../api/authApi";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../store/user-slice";
import MobileHeader from "./MobileHeader";
import Header from "./Header";
import { roles } from "../assets/constants/roles";
import DriverApi from "../api/driver/driverApi";
import CheckinApi from "../api/checkin/checkinApi";
import LabApi from "../api/lab/labApi";
import LocalStorageService from "../api/LocalStorageService";
import { useTranslation } from "react-i18next";



const Layout = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const activeMenu = useSelector(state => state.user.activeMenu);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [t] = useTranslation();

    const getUser = async () => {

      try{
        const _user = await AuthApi.GetUser();
        const route = LocalStorageService.get("route");
        const activeMenu = LocalStorageService.get("activeMenu");

        dispatch(userActions.setUser(_user));

        if(activeMenu)
        dispatch(userActions.setActiveMenu(activeMenu));
        else
        dispatch(userActions.setActiveMenu((_user.menu.showCheckin && roles.checkin) || (_user.menu.showDriver && roles.driver) || (_user.menu.showLaboratory && roles.laboratory)));
       
        route && dispatch(userActions.setRoute(JSON.parse(route))); 
      }
      catch(err){
      if(err.data?.error?.type === "Exception\\TokenInvalidException"){
          LocalStorageService.clear();
          dispatch(userActions.resetValues());
          navigate("/login");
      }
      }
      }

    const getRoutes = async () => {
      const response = await DriverApi.GetRoutes();
      dispatch(userActions.setRoutes(response?.routes));
      dispatch(userActions.setRouteLabel(t("menu.choose_route")));
  }

    const getCheckinLocations = async () => {
      const response = await CheckinApi.GetLocations();
      dispatch(userActions.setRoutes(response?.locations));
      dispatch(userActions.setRouteLabel(t("menu.choose_location")));
    }

    const getLabLocations = async () => {
      const response = await LabApi.GetLocations();
      dispatch(userActions.setRoutes(response?.locations));
      dispatch(userActions.setRouteLabel(t("menu.choose_location")));
    }

  useEffect(() => {
      activeMenu === roles.checkin?  getCheckinLocations() : activeMenu === roles.driver ? getRoutes() : activeMenu === roles.laboratory && getLabLocations();
  }, [activeMenu]);

    useEffect(() => {
        getUser();
    }, [])


    useEffect(() => {
        const handleWindowResize = () => {
          setWindowWidth(window.innerWidth);
        };
    
        window.addEventListener('resize', handleWindowResize);
    
        return () => {
          window.removeEventListener('resize', handleWindowResize);
        };
      });

    return (
        <div className="layout">
           {windowWidth < 730? 
           <MobileHeader/>
           :
           <Header/>}
            <Outlet />

        </div>
    )

}

export default Layout;