import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    activeLoading:false,
    loadingIds: []
};

const loadingStart = (state, action) => {
    const callId = action.payload;
    if (!state.loadingIds.includes(callId)) {
        state.loadingIds.push(callId);
    }
    state.activeLoading = (state.loadingIds.length > 0)
}

const loadingDone = (state, action)  => {
    const callId = action.payload;
    while (state.loadingIds.includes(callId)) {
        const index = state.loadingIds.indexOf(callId);
        state.loadingIds.splice(index, 1);
    }
    state.activeLoading = (state.loadingIds.length > 0)
}

const loadingIndicatorSlice = createSlice({
   name: "loadingIndicator",
   initialState,
   reducers: {
       loadingStart: loadingStart,
       loadingDone: loadingDone,
   }
});

export const loadingIndicatorActions = loadingIndicatorSlice.actions;

export default loadingIndicatorSlice;