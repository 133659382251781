import React, { useState, useEffect } from "react";
import { useSelector, useDispatch} from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { ReactComponent as BurgerMenu} from '../assets/images/burger-menu-green.svg'
import { roles } from "../assets/constants/roles";
import { userActions } from "../store/user-slice";
import LocalStorageService from "../api/LocalStorageService";
import AuthApi from "../api/authApi";
import apiConfig from '../config/api.config';
import SvgIcon from "../helper/SVGIcon";

const Drawer = ({closeDrawer, isOpen}) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const user = useSelector((store) => store.user.user);
    const activeMenu = useSelector(state => state.user.activeMenu);
    const [box, setBox] = useState(null);

    const navigateFun = (route) => {
        navigate(route);
        closeDrawer();
    }

    const changeRole = (route, role) => {
        if(blockNavigation()) return;  

        dispatch(userActions.setActiveMenu(role));
        LocalStorageService.set({activeMenu: role});
        navigateFun(route);
    }

    const logout = async() => {
        if(blockNavigation()) return; 

        try {
            const result = await AuthApi.Logout();
            if(result.success){
            }
        } catch(e) {
            console.error(e)
        } finally {
            LocalStorageService.clear();
            dispatch(userActions.resetValues());
            navigate("/login");
        }
    }

    const blockNavigation = () => {
        if(location.pathname === "/box-status" && box?.forceAccept)
            return true;
        else return false;
    }

    useEffect(() => {
        LocalStorageService.get("package") &&  setBox(JSON.parse(LocalStorageService.get("package")));
    }, []);

    return (
        <div className={`drawer ${isOpen && "drawer-open"}`}>
            <div className="drawer-button">
            <SvgIcon className="logo"/>
            <BurgerMenu onClick={closeDrawer}/>
            </div>
            <ul>
                   <li className="nav-user">Hi, <strong>{user?.first_name}</strong></li>
                  <li onClick={() => navigateFun("/dashboard")}>Home</li>
                  <div className="drawer-roles">
                        <li onClick={() => changeRole("/locations", roles.checkin)} style={{color: activeMenu === roles.checkin && apiConfig.colorPrimary}}>{user?.menu.showCheckin && roles.checkin}</li>
                        <li onClick={() => changeRole("/routes", roles.driver)} style={{color: activeMenu === roles.driver && apiConfig.colorPrimary}}>{user?.menu.showDriver && roles.driver}</li>
                        <li onClick={() => changeRole("/locations", roles.laboratory)} style={{color: activeMenu === roles.laboratory && apiConfig.colorPrimary}}>{user?.menu.showLaboratory && roles.laboratory}</li>
                  </div>
                  <li onClick={() => navigateFun("/settings")}>Settings</li>
                   <li className="nav-logout" onClick={() => logout()}>Logout</li>
           </ul>
        </div>
    )

}

export default Drawer;