import { useState } from "react";
import Modal from "./modal";
import Input from "../inputs/Input";
import Button from "../buttons/Button";
import { useTranslation } from "react-i18next";
import {ReactComponent as DotsIcon} from '../../assets/images/icon-dots-red.svg';
import {ReactComponent as WrongTimingIcon} from '../../assets/images/icon-wrong-timing.svg';
const ErrorModal = ({title, open, setOpen, data, CancelTicket, reason, setReason}) => {
    const [openOther, setOpenOther] = useState(false);
    const [t] = useTranslation();

    const OpenOtherModal =() => {
        setOpen(false);
        setOpenOther(true)
    }
    const onClick = () => {
        CancelTicket({cancelReason: "other", status:"invalid"})
        setOpen(false);
        setOpenOther(false);
    }
    return(
        <>
        <Modal
        open={open}
        setClose={() => setOpen(false)}
        className="nothing-correct-modal"
        >
                <h4>{title}</h4>
                {data?.map(reason => <p key={reason.key} className="nothing-correct-reason" onClick={() => CancelTicket({cancelReason: reason.key, status:"invalid"})}><WrongTimingIcon/><span>{reason.value ?? reason.label}</span></p>)}
                <p className="nothing-correct-reason" onClick={OpenOtherModal}><DotsIcon/> <span>{t("nothing_correct.other")}</span></p>
        </Modal>

        <Modal
        open={openOther}
        setClose={() => {setOpenOther(false); setReason('')}}
        className="nothing-correct-modal"
        >
                <h4>{t("nothing_correct.other")}</h4>
                <Input
            label=""
            placeholder={t("nothing_correct.describe_problem")}
            type="text"
            value={reason}
            onChange={e => setReason(e.target.value)}
            />
            <Button onClick={onClick}>{t("nothing_correct.submit")}</Button>

               
        </Modal>
        </>
    )
}

export default ErrorModal;