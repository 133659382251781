import config from "../../config/api.config";
import ApiService from "../ApiService";
const SampleApi = {
  GetSample: async(data) => {
    return ApiService.Insert(`${config.apiUrl}/api/v1/checkin/getSample`,{
      sampleCode: data.sampleCode,
      locationId: data.locationId
    });
  },
  GetSampleForLabs: async(data) => {
    return ApiService.Insert(`${config.apiUrl}/api/v1/lab/getSample`,{
      sampleCode: data.sampleCode,
      locationId: data.locationId
    });
  },
  GetSampleScanbar: async(data) => {
    return ApiService.Insert(`${config.apiUrl}/api/v1/checkin/getSampleScanbar`,{locationId: data.locationId});
  },
  SetTestStatus: async (data) => {
    return ApiService.Insert(`${config.apiUrl}/api/v1/checkin/setTestStatus`,{
      sampleCode: data.sampleCode,
      status: data.status,
      invalidReason: data.invalidReason,
      invalidOtherReason: data.invalidOtherReason,
      locationId: data.locationId
    });
  },
  CreateSample: async(data) => {
    return ApiService.Insert(`${config.apiUrl}/api/v1/checkin/createSample`,{
      ticketCode: data.ticketCode,
      sampleCode: data.sampleCode,
      locationId: data.locationId
    });
  },
  PackSample: async(data) => {
    return ApiService.Insert(`${config.apiUrl}/api/v1/checkin/packSample`,{
      sampleCode: data.sampleCode,
      containerCode: data.containerCode,  
      locationId: data.locationId
    });
  }
};

export default SampleApi;