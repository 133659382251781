import React from "react";
import {ReactComponent as IconLocation} from "../../assets/images/icon-location.svg";
import "./locationCard.scss";

const LocationCard = ({location}) => {
        return(
            <div className="location-card" >
                <div className="card-top"><span><IconLocation/> {location.address.name}</span> {location.estimatedTimeOfArrival && <span className="arrival-time"><label>ETA</label> {location.estimatedTimeOfArrival}</span>}</div>
                    <div className="card-bottom">
                       {location?.propertyList?.map((prop, index) =>   <div key={index} className="card-bottom--prop">
                         <label className="value" style={{color: prop.flag === "warn" && "#E60000"}}>{prop.value}</label> <label className="label">{prop.label}</label> 
                    </div> )}
                  </div>
           </div>
        )
}

export default LocationCard;