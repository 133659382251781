import React from "react";
import './button.scss';

const Button = ({onClick, style, className, children}) => {

    return (
        <button className={`button ${className}`} onClick={onClick} style={style}>
            {children}
        </button>
    )

}

export default Button;