import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import OverviewChart from "../../components/overviewChart/overviewChart";
import WhiteButton from "../../components/buttons/WhiteButton";
import Scanner from "../../components/scanner/Scanner";
import { useTranslation } from "react-i18next";
import {Html5Qrcode} from "html5-qrcode";
import ContainerApi from "../../api/driver/containerApi";
import { sampleActions } from "../../store/sample-slice";
import { alertActions } from "../../store/alert-slice";
import LocalStorageService from "../../api/LocalStorageService";
import { useNavigate } from "react-router-dom";
import BoxesCard from "../../components/boxesCard/boxesCard";
import LocationCard from "../../components/locationCard/locationCard";

import "./dashboard.scss"

const DriverDashboard = () => {
    const [t] = useTranslation()
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const dashboard = useSelector((store) => store.user.dashboard);
    const [buttonType, setButtonType] = useState(null);
    const [html5QrCode, setHtml5QrCode] = useState();

    const GetPackage = async (ticketId) => {
        await ContainerApi.GetContainer({
            containerCode: ticketId
        })
        .then(res => {
            setButtonType(null);
            dispatch(sampleActions.setPackage(res));
            LocalStorageService.set({package:JSON.stringify(res)})
            navigate("/box-status");
        })
        .catch(err => dispatch(alertActions.addAlert({msg:err?.data?.error.message, type: "error"})))
    }

    useEffect(() => {
        setHtml5QrCode(new Html5Qrcode( "reader"))
}, [])

    return(
        <div className="dashboard">
             <OverviewChart overviewChart={dashboard?.overviewChart}/>
             <div className="dashboard-buttons">
                <WhiteButton onClick={() => setButtonType("box_packing")}>{t("dashboard.scan_box")}</WhiteButton>
                    <Scanner 
                    buttonType={buttonType}
                    setButtonType={setButtonType}
                    html5QrCode={html5QrCode} getTicket={buttonType === "box_packing"? GetPackage : null}
                    boxScanbar={buttonType === "box_packing"? dashboard?.scanBar : null}
                    />
                </div>
                <h4>Route</h4>
                {dashboard?.routes?.map((route,index) => 
                <Fragment key={index}>
                    <p>{route.title}</p>
                        <div className="dashboard-samples dashboard-routes">
                                {route.locations.map((location, index) => <LocationCard key={index} location={location}/>)}
                        </div>
                </Fragment>
              )}
         <h4>Boxes</h4>
         <div className="dashboard-finished">
             {dashboard?.boxes?.map((box, index) => <BoxesCard key={index} card={box}/>)}
           </div>
        </div>
    )
}

export default DriverDashboard;