import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Login from "../pages/Login/Login";
import Dashboard from "../pages/Dashboard/Dashboard";
import Booking from "../pages/Booking/Booking";
import PersonCheck from "../pages/PersonCheck/PersonCheck";
import Layout from "../layout/Layout";
import Sample from "../pages/Sample/Sample";
import BoxStatus from "../pages/Sample/BoxStatus";
import Settings from "../pages/Settings/Settings";
import EmptyTray from "../pages/EmptyTray/EmptyTray";
import GetAllRoutes from "../pages/Dashboard/GetAllRoutes";
import PrivateOutlet from "./PrivateOutlet";

const Routing = () => {
    return (
      <>
        <BrowserRouter basename="/">
          <Routes>
          <Route exact path="/login" element={<Login />} />
          {/* <Route path="/login" element={<Navigate to="/" replace={true} />} /> */}

          <Route path="/" element={<PrivateOutlet />}>
            <Route element={<Layout/>}>
            <Route index element={<Navigate to={"/dashboard"} />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/booking" element={<Booking />} />
                <Route path="/person-check" element={<PersonCheck />} />
                <Route path="/sample" element={<Sample />} />
                <Route path="/box-status" element={<BoxStatus />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/tray" element={<EmptyTray />} />
                <Route path="/routes" element={<GetAllRoutes />} />
                <Route path="/locations" element={<GetAllRoutes />} />    
              </Route>
            </Route>
          </Routes>
        </BrowserRouter>
      </>
    );
};
  
  export default Routing;