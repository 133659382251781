import React, {useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {Html5Qrcode} from "html5-qrcode"
import { layoutActions } from "../../store/layout-slice";
import Avatar from '../../assets/images/avatar.png';
import LocalStorageService from "../../api/LocalStorageService";
import NothingCorrect from "../../components/nothingCorrect/NothingCorrect";
import Scanner from "../../components/scanner/Scanner";
import SampleApi from "../../api/checkin/sampleApi";
import { alertActions } from "../../store/alert-slice";
import { useTranslation } from "react-i18next";
import ListItem from "../../components/listItem/listItem";
import './personCheck.scss';

const PersonCheck = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [ticket, setTicket] = useState(null);
    const [html5QrCode, setHtml5QrCode] = useState();
    const [t] = useTranslation();
    const route = useSelector((store) => store.user.route);

    useEffect(() => {
        dispatch(layoutActions.setLayout("booking.personality_check"));
       setTicket(JSON.parse(LocalStorageService.get("ticket") ?? ""));
       setHtml5QrCode(new Html5Qrcode(/* element id */ "reader"))
    }, []);

    const CreateSample = async (sampleCode) => {
        await SampleApi.CreateSample({
            ticketCode:ticket?.ticket.ticketId,
            sampleCode: sampleCode,
            locationId: route
        })
        .then(res => {
            dispatch(alertActions.addAlert({msg:t("person_check.successfully_scanned"), type: "success"}))
            navigate("/dashboard");

        })
        .catch(err => dispatch(alertActions.addAlert({msg:err?.data?.error.message, type: "error"})))

    }

        return (
            <div className="person-check-wrapper">

                <div className="person-check">
                    <img src={Avatar} className="person-check-avatar"/>
            <p className="person-check-user">{ticket?.ticket.name}</p>
                    {ticket?.personalityProperties.map((prop, index) =>  <ListItem prop={prop} key={index}/>)}
                </div>
                <div className="person-check-buttons">
              <Scanner 
                   setButtonType = {() => {}}
                   buttonType="green"
                   buttonText="Sample Scanen"
                   html5QrCode={html5QrCode}
                   getTicket={CreateSample}
                   boxScanbar={ticket?.scanBar}
                   />
            <NothingCorrect ticketId={ticket?.ticket.ticketId}/>
        </div>

            </div>
        )
}

export default PersonCheck;