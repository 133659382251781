import React, { useState } from "react";
import './layout.scss';
import { useTranslation } from 'react-i18next';
import {ReactComponent as GbFlag} from '../assets/images/icon-gb-flag.svg';
import {ReactComponent as DeFlag} from '../assets/images/icon-de-flag.svg';
import { useOutsideClick } from "../hook/clickOutside";
import {useNavigate} from "react-router-dom";

const LanguageSwitch = () => {
    const { i18n } = useTranslation();
    const [showDropdown, setShowDropdown] = useState(false);
    const navigate = useNavigate();

    const changeLanguage = (lang) => {
        i18n.changeLanguage(lang);
        setShowDropdown(false)
        console.log('navigate!')
        navigate("/locations")
    }

    const handleClickOutside = () => setShowDropdown(false);

    const ref = useOutsideClick(handleClickOutside);

    return (
        <div className="language-switch" ref={ref}>
            <button onClick={() => setShowDropdown(!showDropdown)}>{i18n.language === "en"? <GbFlag/> : <DeFlag/>}</button>
            {showDropdown && <div className="language-dropdown">
                <p onClick={() => changeLanguage('en')} style={{color: i18n.language !== "en" && "#CBD2D4"}}><GbFlag/> En</p>
                <p onClick={() => changeLanguage('de')} style={{color: i18n.language !== "de" && "#CBD2D4"}}> <DeFlag/> De</p>

            </div>}

        </div>
    )
}

export default LanguageSwitch;