import React, {useState} from "react";
import { useNavigate } from "react-router-dom";
import Input from "../../components/inputs/Input";
import {ReactComponent as User} from '../../assets/images/user.svg';
import {ReactComponent as Locker} from '../../assets/images/locker.svg';
import Button from "../../components/buttons/Button";
import AuthApi from "../../api/authApi";
import { useDispatch } from "react-redux";
import { alertActions } from "../../store/alert-slice";
import LocalStorageService from "../../api/LocalStorageService";
import { useTranslation } from "react-i18next";
import axios from "axios";

const FirstStep = ({onChangeStep}) => {
    const navigate = useNavigate();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const dispatch = useDispatch();
    const [t] = useTranslation();

    const Login = async () => {      
        await AuthApi.Login({
            username,
             password
         })
        .then(res => {
            if(res?.twoFactorAuthRequired){
                onChangeStep(1);
                LocalStorageService.set({accessToken2Fa: res.token})
            }
            else if(res.success){
            LocalStorageService.set({accessToken: res.token})
            navigate("/dashboard");
        }

        })
        .catch(err => dispatch(alertActions.addAlert({msg:err?.data?.error.message, type: "error"})))

    }

    return (
        <>
            <div className="login-inputs">
            <Input
            label={t("login.email")}
            placeholder={t("login.your_email")}
            icon={User}
            type="text"
            value={username}
            onChange={e => setUsername(e.target.value)}
            />
            <Input
            label={t("login.password")}
            placeholder={t("login.your_password")}
            icon={Locker}
            type="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            />
        </div>
        <Button
         onClick={Login}
          >{t("login.next")}</Button>
        </>
    )

}

export default FirstStep;