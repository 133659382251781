import React from "react";
import './button.scss';

const WhiteButton = ({onClick, children, className, ...props}) => {

    return (
        <button className={`button button--white ${className}`} onClick={onClick} {...props}>
            {children}
        </button>
    )

}

export default WhiteButton;