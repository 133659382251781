import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/buttons/Button";
import ReactInputVerificationCode from 'react-input-verification-code';
import { useTranslation } from "react-i18next";
import { useState } from "react";
import AuthApi from "../../api/authApi";
import LocalStorageService from "../../api/LocalStorageService";
import { useDispatch } from "react-redux";
import { alertActions } from "../../store/alert-slice";

const SecondStep = ({onChangeStep}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [t] = useTranslation();
    const [code, setCode] = useState("");

    const Login = async() => {
        await AuthApi.Login({
            token: LocalStorageService.get("accessToken2Fa"),
            code
        })
        .then(res => {;
          if(res.success){
            onChangeStep(0);
            LocalStorageService.set({accessToken: res.token})
            navigate("/dashboard");
        }
        })
        .catch(err => dispatch(alertActions.addAlert({msg:err?.data?.error.message, type: "error"})))

    }

    return (
        <>
        <div className="login-inputs second-step">
            <h3>Enter Code to Verify</h3>
            <p>Lorem ipsum dolor sit amet lorem ipsum dolor</p>
            <div className="code-input">
                <ReactInputVerificationCode 
                placeholder=""
                value={code}
                onChange={(e) => setCode(e)}
                length={6}/>
            </div>

    </div>
    <Button
     onClick={Login}
      >{t("login.next")}</Button>
    </>
    )
}

export default SecondStep;