import React from "react";
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import "./overviewChart.scss";
import apiConfig from "../../config/api.config";

const OverviewChart = ({overviewChart}) => {
        return(<div className="overview-chart">
        {overviewChart?.map((chart, index) => <div className="chart-circle" key={index}>
            <p>{chart.label}</p>
            <div className="progress-bar">
            <CircularProgressbarWithChildren value={chart.data.max === 0?chart.data.max : chart.data.value}  maxValue={chart.data.max ===0? 100 :chart.data.max}
             strokeWidth={7}
          styles={{
            root: {},
            path: {
              stroke: apiConfig.colorPrimary, 
              boxShadow: '4px 10px 9px rgba(0, 195, 200, 0.2)'
            },
            trail: {
              stroke: '#fff',
            },
            text: {
              fill: '#f88',
              fontSize: '16px',
            },
            background: {
              fill: '#3e98c7',
            },
          }}
            >
                <p className="progressbar-value"><span>{chart.data.value}</span>{chart.data.max !== 0 && `/${chart.data.max}`}</p>
                <p className="progressbar-label">{chart.data.unitLabel}</p>

      </CircularProgressbarWithChildren>
        </div>
        </div>
        )}

    </div>)
}

export default OverviewChart;