
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import httpBackend from "i18next-http-backend";
import LanguageDetector from 'i18next-browser-languagedetector';
import store from '../store';
import { alertActions } from '../store/alert-slice';

const options = {
  interpolation: {
  },

  backend: {
    loadPath: '/locales/{{lng}}.json',
  },

  debug: true,
  lng: 'de',
  fallbackLng: 'en',

  ns: ['common'],
  defaultNS: 'common',
  react: {
    useSuspense: true,
    bindI18n: 'languageChanged loaded',
    bindStore: 'added removed',
    nsMode: 'default'
  },
};

i18n
  .use(httpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(options)

i18n.on('failedLoading', function(lng, ns, msg) {
    store.dispatch(alertActions.addAlert({msg:msg, type: "error"}))
})

export default i18n;