import React, {useState, useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Scanner from "../../components/scanner/Scanner";
import {Html5Qrcode} from "html5-qrcode";
import LocalStorageService from "../../api/LocalStorageService";
import { alertActions } from "../../store/alert-slice";
import ContainerApi from "../../api/lab/containerApi";
import { sampleActions } from "../../store/sample-slice";
import OutlinedButton from "../../components/buttons/OutlinedButton";
import WhiteButton from "../../components/buttons/WhiteButton";
import LocationCard from "../../components/locationCard/locationCard";
import { PieChart } from "react-minimal-pie-chart";
import SampleApi from "../../api/checkin/sampleApi";

const LabratoryDashboard = () => {
    const [t] = useTranslation()
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const dashboard = useSelector((store) => store.user.dashboard);
    const route = useSelector((store) => store.user.route);
    const [buttonType, setButtonType] = useState(null);
    const [html5QrCode, setHtml5QrCode] = useState();
    const [sampleScanbar, setSampleScanbar] = useState(null);

    const GetPackage = async (ticketId) => {
        await ContainerApi.GetContainer({
            locationId: route,
            containerCode: ticketId
        })
        .then(res => {
            setButtonType(null);
            dispatch(sampleActions.setPackage(res));
            LocalStorageService.set({package:JSON.stringify(res)})
            navigate(buttonType === "scan_delivery"? "/box-status" : "/tray");
        })
        .catch(err => dispatch(alertActions.addAlert({msg:err?.data?.error.message, type: "error"})))
    }

    const GetSample = async (ticketId) => {
        await SampleApi.GetSampleForLabs({
            locationId: route,
            sampleCode: ticketId
        })
        .then(res => {
            dispatch(sampleActions.setSample(res));
            LocalStorageService.set({sample:JSON.stringify(res)})
            navigate("/sample");

        })
        .catch(err => dispatch(alertActions.addAlert({msg:err?.data?.error.message, type: "error"})))
    }

    const GetSampleScanbar = async() => {
        await SampleApi.GetSampleScanbar({
            locationId: route
        })
        .then(res => setSampleScanbar(res))
        .catch(err => dispatch(alertActions.addAlert({msg:err?.data?.error.message, type: "error"})))
    }


    useEffect(() => {
        setHtml5QrCode(new Html5Qrcode( "reader"))
        route && GetSampleScanbar();
}, [route])


    return(
        <div className="dashboard">
            <div className="lab-overview-chart">
                {dashboard?.overviewChart?.map((chart, index) => <div key={index} className="chart-pie">
                <p className="chart-label">{chart.label}</p>
                <p className="chart-title">{chart.data.title}</p>
                <div className="chart-pie-items">
                <PieChart
                    data={chart.data.items?.map(item => ({value: item.percent, label:item.value, color: item.color}))}
                    radius={40}
                    segmentsShift={0.1}
                    label={({ dataEntry }) => dataEntry.label}
                    labelStyle={{
                        fontWeight: "400",
                        fontSize: "6px",
                        letterSpacing: "0.02em",
                        fill: "#FFF"
                    }}
                    style={{
                        maxWidth:"250px",
                        maxHeight:"250px"
                    }}
                />
                        <ul key={index} className="chart-item">
                        {chart?.data?.items?.map((item, index) =>
                            <li key={index}>
                                <span style={{backgroundColor: item.color}} className="chart-item--dot"></span>
                                {item.label}
                                <span style={{color: item.color}} className="chart-item--value">({item.value})</span>
                            </li>
                       )}
                        </ul>
                </div>
                </div>)}

            </div>
              <div className="dashboard-buttons">
                <WhiteButton onClick={() => setButtonType("scan_delivery")}>{t("dashboard.scan_delivery")}</WhiteButton>
                <OutlinedButton style={{marginTop:"20px"}} onClick={() => setButtonType("pack_samples")}>{t("dashboard.pack_samples")}</OutlinedButton>
                <OutlinedButton style={{marginTop:"20px"}} onClick={() => setButtonType("take_subsample")}>{t("dashboard.take_subsample")}</OutlinedButton>
                <Scanner
                    buttonType={buttonType}
                    setButtonType={setButtonType}
                    html5QrCode={html5QrCode}
                    getTicket={buttonType === "scan_delivery" || buttonType === "pack_samples"? GetPackage : buttonType === "take_subsample"? GetSample : () => {}}
                    boxScanbar={buttonType === "scan_delivery" || buttonType === "pack_samples"? dashboard?.scanBar : buttonType === "take_subsample"? sampleScanbar : null}
                />
                </div>
                <h4>Upcoming</h4>
                {dashboard?.upcomingDeliveries?.map((delivery,index) => 
                        <div className="dashboard-samples dashboard-routes" key={index}>
                            <LocationCard key={index} location={delivery}/>
                        </div>
              )}
                <h4>Accepted</h4>
                {dashboard?.acceptedDeliveries?.map((delivery,index) => 
                        <div className="dashboard-samples dashboard-routes" key={index}>
                           <LocationCard key={index} location={delivery}/>
                        </div>
              )}
        </div>
    )
}

export default LabratoryDashboard;