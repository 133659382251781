import React from "react";
import './button.scss';

const OutlinedButton = ({onClick, children, ...props}) => {

    return (
        <button className="button button--white button--outlined" onClick={onClick} {...props}>
            {children}
        </button>
    )

}

export default OutlinedButton;