import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector,useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { layoutActions } from "../../store/layout-slice";
import {ReactComponent as DateIcon} from '../../assets/images/icon-date.svg';
import {ReactComponent as ClockIcon} from '../../assets/images/icon-clock.svg';
import {ReactComponent as PinIcon} from '../../assets/images/icon-pin.svg';
import {ReactComponent as DotIcon} from '../../assets/images/icon-dot.svg';
import LocalStorageService from "../../api/LocalStorageService";
import Button from "../../components/buttons/Button";
import NothingCorrect from "../../components/nothingCorrect/NothingCorrect";

import './booking.scss';

const Booking = ()=> {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [ticket, setTicket] = useState(null);
    const data = useSelector(state => state.ticket.ticket);
    const [t] = useTranslation();

    useEffect(() => {
        dispatch(layoutActions.setLayout("booking.booking"))
        if(data) setTicket(data);
        else setTicket(JSON.parse(LocalStorageService.get("ticket") ?? ""));
    }, []);

    return (
        <div className="booking-wrapper">
        <div className="booking">
            <p className="booking-status-new">New</p>
            <p className="booking-user">{ticket?.ticket.name}</p>
            <ul className="booking-info-list">
                <li><DateIcon/> <span>{ticket?.ticket.date}</span></li>
                <li><ClockIcon/> <span>{ticket?.ticket.time}</span> <p className={`booking-time-badge ${!["tooEarly", "tooLate"].includes(ticket?.ticket.state)? "time-badge-in-time" : "time-badge-early"}`}>{ticket?.ticket.stateLabel}</p></li>
                <li><PinIcon/> <span>{ticket?.ticket.location}</span></li>
            </ul>

            <ul className="test-details-list">
                {ticket?.propertyBar.map((prop, index) =>  <li key={index} style={{width: `${100/ticket?.propertyBar.length}%`}}>
                    <DotIcon/>
                    <p>
                        <span>{prop.label}</span>
                        <strong>{prop.value}</strong>
                    </p>
                </li>)}
            </ul>

        </div>
        <div className="booking-buttons">
            <Button onClick={() => navigate("/person-check")}>{t("booking.personality_check")}</Button>
            <NothingCorrect ticketId={ticket?.ticket?.ticketId}/>
        </div>
        </div>
    )

}

export default Booking;