import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ReactComponent as Logo} from "../assets/images/logo-lis.svg";
import LanguageSwitch from './LanguageSwitch';
import AccountSwitch from "./AccountSwitch";
import { roles } from "../assets/constants/roles";
import SvgIcon from "../helper/SVGIcon";

const Header = () => {
    const navigate = useNavigate();
    const data = useSelector(state => state.layout.layout);
    const activeMenu = useSelector(state => state.user.activeMenu);
    const routeName = useSelector(state => state.user.routeName);
    const [t] = useTranslation();

    return (
        <header>
            <div className="header-content">
                <SvgIcon/>
                <div className="content-left">
                    <ul>
                        <li onClick={() => navigate("/dashboard")}>Home</li>
                        <li onClick={() => navigate("/settings")}>Settings</li>
                        <AccountSwitch/>
                    </ul>
        <LanguageSwitch/>
                </div>

            </div>
            {data!== null? <p>{t(data)}</p> :<p>
                <label><span>{activeMenu === roles.driver? "Logistics" : activeMenu === roles.laboratory? "Lab" : "Center"}</span> Dashboard</label>
            <label className="route-name">{routeName}</label>
                </p>}
        </header>
    )
}

export default Header;