import config from "../../config/api.config";
import ApiService from "../ApiService";

const LabApi = {
  GetDashboard: async(data) => {
    return ApiService.Insert(`${config.apiUrl}/api/v1/lab/getDashboard`,{locationId: data.locationId});
  },
  GetLocations: async() => {
    return ApiService.Insert(`${config.apiUrl}/api/v1/lab/getLocations`,{});
  }
};

export default LabApi;