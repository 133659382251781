import config from "../../config/api.config";
import ApiService from "../ApiService";

const DriverApi = {
  GetDashboard: async(data) => {
    return ApiService.Insert(`${config.apiUrl}/api/v1/driver/getDashboard`,{routeId: data.routeId});
  },
  GetRoutes: async() => {
    return ApiService.Insert(`${config.apiUrl}/api/v1/driver/getRoutes`,{});
  },
};

export default DriverApi;