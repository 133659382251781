const { REACT_APP_API_URL, 
        REACT_APP_TENANT_ID, 
        REACT_APP_COLOR_PRIMARY, 
        REACT_APP_COLOR_SECONDARY, 
        REACT_APP_COLOR_TERTIARY,
        REACT_APP_LOGIN_CODE_BACKGROUND_COLOR,
        REACT_APP_LOGIN_CODE_BACKGROUND_COLOR_ACTIVE,
        REACT_APP_LOGIN_CODE_BORDER_COLOR,
        REACT_APP_BACKGROUND_DESKTOP,
        REACT_APP_BACKGROUND_TABLET,
        REACT_APP_BACKGROUND_MOBILE
      } = process.env;
export class ApiConfig {

  constructor() {
    this.apiUrl = REACT_APP_API_URL;
    this.tenantId = REACT_APP_TENANT_ID;
    this.colorPrimary = REACT_APP_COLOR_PRIMARY;
    this.colorSecondary = REACT_APP_COLOR_SECONDARY;
    this.colorTertiary = REACT_APP_COLOR_TERTIARY;

    this.loginCodeBackground = REACT_APP_LOGIN_CODE_BACKGROUND_COLOR;
    this.loginCodeBackgroundActive = REACT_APP_LOGIN_CODE_BACKGROUND_COLOR_ACTIVE;
    this.loginCodeBorder = REACT_APP_LOGIN_CODE_BORDER_COLOR;

    this.backgroundDesktop = REACT_APP_BACKGROUND_DESKTOP;
    this.backgroundTablet = REACT_APP_BACKGROUND_TABLET;
    this.backgroundMobile = REACT_APP_BACKGROUND_MOBILE;
  }
}
const config = new ApiConfig();
export default config;