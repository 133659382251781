import React, {useState, useEffect} from "react";
import { layoutActions } from "../../store/layout-slice";
import { useDispatch, useSelector } from "react-redux";
import LocalStorageService from "../../api/LocalStorageService";
import OutlinedButton from "../../components/buttons/OutlinedButton";
import Button from "../../components/buttons/Button";
import "./sample.scss";
import { secondsToMinutes } from "../../helper/secondsToMinutes";
import {ReactComponent as TimerRed} from "../../assets/images/icon-timer-red.svg";
import {ReactComponent as TimeYellow} from "../../assets/images/icon-timer-yellow.svg";
import {ReactComponent as TimerGreen} from "../../assets/images/icon-timer-green.svg";
import SampleApi from "../../api/checkin/sampleApi";
import SampleApiLab from "../../api/lab/sampleApi";
import ContainerApi from "../../api/checkin/containerApi";
import { alertActions } from "../../store/alert-slice";
import ErrorModal from "../../components/modal/errorModal";
import { useTranslation } from "react-i18next";
import Modal from "../../components/modal/modal";
import WhiteButton from "../../components/buttons/WhiteButton";
import {Html5Qrcode} from "html5-qrcode"
import Scanner from "../../components/scanner/Scanner";
import { sampleActions } from "../../store/sample-slice";
import {Link, useNavigate} from "react-router-dom";
import { roles } from "../../assets/constants/roles";
import ListItem from "../../components/listItem/listItem";

const Sample = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [sample, setSample] = useState(null);
    const data = useSelector(state => state.sample.sample);
    const route = useSelector((store) => store.user.route);
    const activeMenu = useSelector(state => state.user.activeMenu);
    const user = useSelector((store) => store.user.user);
    const [timeLeft, setTimeLeft] = useState("");
    const [threshold, setThreshold] = useState({});
    const [reason, setReason] = useState("");
    const [t] = useTranslation();
    const [open, setOpen] = useState(false);
    const [sureModal, setSureModal] = useState(false);
    const [status, setStatus] = useState("");
    const [html5QrCode, setHtml5QrCode] = useState();
    const [sampleScanbar, setSampleScanbar] = useState(null);
    const [buttonType, setButtonType] = useState(null);

    useEffect(() => {
        dispatch(layoutActions.setLayout("sample.probe"))
        if(data) setSample(data);
        else setSample(JSON.parse(LocalStorageService.get("sample") ?? ""));
    }, []);

    useEffect(() => {
        setTimeLeft(sample?.timeline?.timing?.timeLeft);
    }, [sample])

    useEffect(() => {
     const interval =   setInterval(() => setTimeLeft(timeLeft -1),1000);
     setThreshold(sample?.timeline?.timing?.thresholds?.find(time => timeLeft > time.from && timeLeft < time.to))
     return () => clearInterval(interval);
    }, [timeLeft])
    
    const getIcon = (type) => {
        switch(type){
           case "tooLate": return <TimerRed/>;
           case "ready": return <TimerGreen/>
           case "notReady": return <TimeYellow/>;
        }
    }
    const setTestStatus = async (data) => {
        await SampleApi.SetTestStatus({
            sampleCode: sample?.sample.sampleId, 
            locationId: route,
            status: data.status ?? null,   // this is one of the received status keys from getSample.statusTypes
            invalidReason: data.cancelReason ?? null,         
            invalidOtherReason: reason ?? null
        })
        .then(res => {
            if(res.success){
               dispatch(alertActions.addAlert({msg:t("sample.successfully_set_status"), type: "success"}))
                navigate("/dashboard");
            }
           })
        .catch(err => dispatch(alertActions.addAlert({msg:err?.data?.error.message, type: "error"})))
    }

    const onStatusTypeClick = (type) => {
        switch(type){
            case "invalid": setOpen(true); break;
            case "positive": setStatus(type); setSureModal(true); break;
            case "negative": setStatus(type); setSureModal(true); break;
        }
    }


    const GetSampleScanbar = async() => {
        await SampleApi.GetSampleScanbar({
            locationId: route
        })
        .then(res => setSampleScanbar(res))
        .catch(err => dispatch(alertActions.addAlert({msg:err?.data?.error.message, type: "error"})))
    }

    const GetSample = async (ticketId) => {
        await SampleApi.GetSample({
            locationId: route,
            sampleCode: ticketId
        })
        .then(res => {
            setButtonType(null);
             setSample(res);
        })
        .catch(err => dispatch(alertActions.addAlert({msg:err?.data?.error.message, type: "error"})))
    }

    const GetPackage = async (ticketId) => {
        await ContainerApi.GetContainer({
            locationId: route,
            containerCode: ticketId
        })
        .then(res => {
            setButtonType(null);
            dispatch(sampleActions.setPackage(res));
            LocalStorageService.set({package:JSON.stringify(res)})
            navigate("/box-status");
        })
        .catch(err => dispatch(alertActions.addAlert({msg:err?.data?.error.message, type: "error"})))
    }

    const GetSubsample = async (ticketId) => {
        await SampleApiLab.CreateSubsample({
            locationId: route,
            sampleCode: sample?.sample.sampleId,
            subSampleCode: ticketId
        })
        .then(res => {
            if(res.success){
                dispatch(alertActions.addAlert({msg:t("sample.successfully_created_subsample"), type: "success"}))
                 navigate("/dashboard");
             }
        })
        .catch(err => dispatch(alertActions.addAlert({msg:err?.data?.error.message, type: "error"})))
    }

    useEffect(() => {
        setHtml5QrCode(new Html5Qrcode( "reader"))
       user && GetSampleScanbar();
}, [user])

    console.log(sample?.sampleInfo);

    return (
        <div className="sample-wrapper">
            <div className="sample">
                <div className="sample-card">
                    <p>
                        {sample?.sample?.typeLabel}
                        <label className={`${sample?.sample?.state}-label`}>{sample?.sample?.stateLabel}</label>
                    </p>
                    <p className="sample-id">{sample?.sample?.subTypeLabel}</p>
                </div>

                {sample?.timeline?.showTiming &&
                    <div className="timeline">
                        <p className="timeline-time-left">Timeline <span className={threshold?.type}>{getIcon(threshold?.type)} {secondsToMinutes(timeLeft)}</span></p>
                        <p className={`time-left-label time-left-label-${threshold?.type}`}>{threshold?.label}</p>
                        <div className="steps">
                            {sample?.timeline?.timing?.steps.map((step,index) => <div key={index} className="step-card">
                                    <p className="step-label">{step.label}</p>
                                    <p className="step-value">{step.value}</p>
                            </div>
                                )}
                        </div>
                    </div>
                }

                {sample?.showSetTestStatus &&
                    <div className="test-status">
                        <p>Test status</p>
                        <div className="test-status-buttons">
                            {sample?.timeline?.statusTypes.map(type =>
                                <button onClick={() => onStatusTypeClick(type.key)}
                                        key={type.key}
                                        className={`button-${type.key}`}
                                        disabled={!threshold?.allowSetStatus}> {type.label}
                                </button>
                            )}
                        </div>
                    </div>
                }

                {sample?.sampleInfo?.propertyList?.map((prop, index) =>  <ListItem prop={prop} key={index}/>)}

            </div>
        <div className="sample-buttons">
           {activeMenu !== roles.laboratory && sample?.timeline?.allowReboxing && <Button onClick={() => setButtonType("box_packing")}>{t("sample.rebox_sample")}</Button>}
           {activeMenu !== roles.laboratory && sample?.timeline?.allowBoxing && <Button onClick={() => setButtonType("box_packing")} className="sample-box-button">{t("sample.box_sample")}</Button>}
           {sample?.allowBackToDashboard && <Button onClick={() => navigate("/dashboard")} className="sample-box-button">{t("sample.back_to_dashboard")}</Button>}
            {activeMenu === roles.laboratory && <Button onClick={() => setButtonType("scan_subsample")} className="sample-box-button">{t("sample.scan_subsample")}</Button>}
            <OutlinedButton onClick={() => setButtonType("scane_probe")}>{t("sample.scan_again")}</OutlinedButton>
        </div>
        <ErrorModal 
           title={t("sample.invalid_test")}
           open = {open}
           setOpen = {setOpen}
           CancelTicket={setTestStatus}
           data={sample?.timeline?.invalidReasons}
           reason = {reason}
           setReason = {setReason}
           />   

           <Modal 
           open={sureModal}
           setOpen={setSureModal}
           setClose={() => setSureModal(false)}
           className="sample-set-status-modal"
           >
                <p>Are you sure you want to mark the test as <label>{sample?.timeline?.statusTypes?.find(type => type.key === status)?.label}</label>?</p>
                <div className="set-status-buttons">
                    <Button onClick={() => {
                        setTestStatus({status:status});
                        setStatus("");
                        setSureModal(false)
                    }}>Yes</Button>
                    <WhiteButton onClick={() => {
                        setStatus("");
                        setSureModal(false)
                    }}>No</WhiteButton>
                </div>
           </Modal>

           <Scanner 
              setButtonType={setButtonType}
             buttonType={buttonType}
             html5QrCode={html5QrCode} getTicket={buttonType === "box_packing"? GetPackage: buttonType === "scane_probe"? GetSample : buttonType === "scan_subsample"? GetSubsample : null}
             boxScanbar={buttonType === "scane_probe"? sampleScanbar : buttonType === "box_packing"? sample?.timeline?.boxScanBar : buttonType === "scan_subsample"? {title: "Scan the subpsample"} : null}/>
        </div>
    )

}

export default Sample;