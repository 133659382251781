import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    sample:null,
    package:null
};

const setSample = (state, action) => {
    state.sample = action.payload;
 }
 const getSample = (state)  => {
   return state.sample;
 }

 const setPackage = (state, action) => {
   state.package = action.payload;
}
const getPackage = (state)  => {
  return state.package;
}

const sampleSlice = createSlice({
   name: "sample",
   initialState,
   reducers: {
      setSample,
      getSample,
      setPackage,
      getPackage
   }
});

export const sampleActions = sampleSlice.actions;

export default sampleSlice;