import React,  { useEffect } from "react";
import WhiteButton from "../../components/buttons/WhiteButton";
import { useNavigate } from "react-router-dom";
import "./dashboard.scss";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../store/user-slice";
import { layoutActions } from "../../store/layout-slice";
import LocalStorageService from "../../api/LocalStorageService";

const GetAllRoutes = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const routes = useSelector(state => state.user.routes);
    const routeLabel = useSelector(state => state.user.routeLabel);
    const activeMenu = useSelector(state => state.user.activeMenu);

    const setRoute = (route) => {
        dispatch(userActions.setRoute(route));
        LocalStorageService.set({route: JSON.stringify(route)});
        navigate("/dashboard");
    }

    useEffect(() => {
        dispatch(layoutActions.setLayout("empty"));
        dispatch(userActions.setRoute(null)); 
    }, []);


    return (
        <div className="get-routes">
            <p className="get-routes-label"><span>{activeMenu}</span> - {routeLabel}</p>
            {routes?.map((route, index) => <WhiteButton key={index} onClick={() => setRoute(route)}>{route.label}</WhiteButton>)}
        </div>
    )
}

export default GetAllRoutes;