import React, {useEffect} from "react";
import CheckinDashboard from "./CheckinDashboard";
import DriverDashboard from "./DriverDashboard";
import LabratoryDashboard from "./LabratoryDashboard";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../store/user-slice";
import { Navigate } from "react-router-dom";
import { layoutActions } from "../../store/layout-slice";
import { roles } from "../../assets/constants/roles";
import CheckinApi from "../../api/checkin/checkinApi";
import DriverApi from "../../api/driver/driverApi";
import LabApi from "../../api/lab/labApi";
import { alertActions } from "../../store/alert-slice";

const Dashboard = () => {  
    const route = useSelector(state => state.user.route);
    const activeMenu = useSelector(state => state.user.activeMenu);
    const dispatch = useDispatch();

    const getCheckinDashboard = async () => {
        try{
        const response = await CheckinApi.GetDashboard({ locationId: route })
        dispatch(userActions.setDashboard(response))
    }catch(err){
        dispatch(userActions.setDashboard(null));
        dispatch(userActions.setRoute(null));
        dispatch(alertActions.addAlert({msg:err?.data?.error.message, type: "error"}))
    }
    }

    const getDriverDashboard = async () => {
        try{
        const response = await DriverApi.GetDashboard({routeId: route})
        dispatch(userActions.setDashboard(response))
         }catch(err){
             dispatch(userActions.setDashboard(null));
             dispatch(userActions.setRoute(null));
             dispatch(alertActions.addAlert({msg:err?.data?.error.message, type: "error"}))
    }
    }


    const getLabDashboard = async () => {
        try{
            const response = await LabApi.GetDashboard({locationId: route})
            dispatch(userActions.setDashboard(response))
        }catch(err){
            dispatch(userActions.setDashboard(null));
            dispatch(userActions.setRoute(null));
            dispatch(alertActions.addAlert({msg:err?.data?.error.message, type: "error"}))
        }
    }

    const getDashboard = (role) => {
        switch(role){
            case roles.checkin: getCheckinDashboard(); break;
            case roles.driver: getDriverDashboard(); break;
            case roles.laboratory: getLabDashboard(); break;
        }
      
    }

    useEffect(()=> {
        dispatch(layoutActions.setLayout(null))
        route && activeMenu && getDashboard(activeMenu);
    }, [route, activeMenu])
    return (
        <>
           {activeMenu === roles.checkin && (!route? <Navigate to="/locations"/> :<CheckinDashboard/>)}
           {activeMenu === roles.laboratory && (!route? <Navigate to="/locations"/> :<LabratoryDashboard/>)}
           {activeMenu === roles.driver && (!route? <Navigate to="/routes"/> : <DriverDashboard/>)}
        </>
        )

}

export default Dashboard;