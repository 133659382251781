import React, { useState } from "react";
import './input.scss';
import {ReactComponent as EyeIcon} from '../../assets/images/eye.svg';
import {ReactComponent as EyeCrossedIcon} from '../../assets/images/eye-crossed.svg';

const Input = ({label, placeholder, value, onChange, ...props}) => {
    const [type, setType] = useState("text");

    useState(() => { setType(props.type) }, [props.type])

    return (
        <div className="input">
            <label>{label}</label>
            <div>
               {props.icon && <div className="input-icon">
                        <props.icon/>
                        </div>}
                        <input 
                        value={value}
                        onChange={onChange}
                        type={type}
                        placeholder={placeholder}/>
                        {props.type == "password" && 
                        (type === "password"?
                        <EyeIcon className="input-eye-icon" onClick={() => setType("text")}/>
                        :
                        <EyeCrossedIcon className="input-eye-icon" onClick={() => setType("password")}/>
                        )
                        }
            </div>
        </div>
    )


}

export default Input;