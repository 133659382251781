import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LocalStorageService from "../../api/LocalStorageService";
import { layoutActions } from "../../store/layout-slice";
import ListItem from "../../components/listItem/listItem";
import Button from "../../components/buttons/Button";
import OutlinedButton from "../../components/buttons/OutlinedButton";
import Scanner from "../../components/scanner/Scanner";
import SampleApi from "../../api/lab/sampleApi";
import { alertActions } from "../../store/alert-slice";
import {Html5Qrcode} from "html5-qrcode"
import { useTranslation } from "react-i18next";
import { roles } from "../../assets/constants/roles";
import Modal from "../../components/modal/modal";
import { ReactComponent as ErrorIcon } from "../../assets/images/icon-error.svg";

import "./emptyTray.scss";

const EmptyTray = () => {
    const navigate = useNavigate();
    const [t] = useTranslation();
    const data = useSelector(state => state.sample.package);
    const route = useSelector((store) => store.user.route);
    const dispatch = useDispatch();
    const [box, setBox] = useState(null);
    const [buttonType, setButtonType] = useState(null);
    const [html5QrCode, setHtml5QrCode] = useState();
    const activeMenu = useSelector(state => state.user.activeMenu);
    const [scannedSamples, setScannedSamples] = useState(0);
    const [errorMessage, setErrorMessage] = useState("");
    const [errorModal, setErrorModal] = useState(false);
    const [showBlocker, setShowBlocker] = useState(false);
    const [finishScanning, setFinishScanning] = useState(false);

    useEffect(() => {
        if(data) setBox(data);
        else setBox(JSON.parse(LocalStorageService.get("package") ?? ""));

        setHtml5QrCode(new Html5Qrcode( "reader"))
    }, []);

    useEffect(() => {
        dispatch(layoutActions.setLayout("container.tray"));
    }, [box])

    const PackSample = async(sampleId) => {
        setShowBlocker(true);
        await SampleApi.PackSample({
            locationId: route,
            containerCode: box?.container.containerCode,
            sampleCode: sampleId
        })
        .then(res => {
         if(res.success){
            dispatch(alertActions.addAlert({msg:t("sample.successfully_added_test", {value: box?.container.containerCode}), type: "success"}));
            let scannedSamplesTemp = scannedSamples;
            setScannedSamples(++scannedSamplesTemp)
         }
        setShowBlocker(false);
        })
        .catch(err => {
            setShowBlocker(false);
            setErrorMessage(err?.data?.error.message);
            setErrorModal(true);
        })
    }

    return (
        <div className="box-status-wrapper tray-wrapper">
           {showBlocker && <div className="tray-wrapper-blocker"></div>}
            <div className="sample dashboard">
            {box?.containerProperties?.map((prop, index) =>  <ListItem prop={prop} key={index}/>)}
            {box?.status?.show && <ListItem prop={{
                label:"Box status",
                value:box?.status?.value
            }}>
            </ListItem>}

            {box?.samples.length > 0 && <>
                <h4>Samples</h4>
                    <div className="dashboard-samples">
                        {box?.samples?.map((sample,index) => <div key={index} className="samples-card" >
                            <div className="card-top">{sample.sampleCode} <span className={`test-type ${sample.sampleType}`}>{sample.sampleTypeLabel}</span></div>
                                {sample.properties.map((prop, index) =>   <div className="card-bottom" key={index}>
                                <label className="label">{prop.label}</label> <label className="value">{prop.value}</label>
                            </div>)}
                        </div>)}
                    </div>
                    </>}
           </div>
           <div className="sample-buttons">
           {activeMenu === roles.laboratory && !finishScanning &&  <Button onClick={() => setButtonType("add_samples")}>{t("sample.add_samples")}</Button>}
           {activeMenu === roles.laboratory && !finishScanning && <OutlinedButton onClick={() => setButtonType("pack_samples")}>Scan again</OutlinedButton>}
           {activeMenu === roles.laboratory && finishScanning && <Button onClick={() => navigate("/dashboard")}>{t("scan.done")}</Button>}
        </div>
        <Scanner 
             setButtonType={setButtonType}
             buttonType={buttonType}
             html5QrCode={html5QrCode} 
             getTicket={PackSample}
             setFinishScanning={setFinishScanning}
             boxScanbar={{title: "Scan samples!",
             description: "You can scan multiple samples in one go to add into the empty tray.",
             numberOfSamples: <p className="scanner-list-samples">Number of scanned samples <strong>{scannedSamples}</strong></p>
             }}/>
        <Modal
        className="tray-error-modal"
        open={errorModal}
        setClose={() => {}}>
            <ErrorIcon/>
             <p>{errorMessage}</p>
             <Button onClick={() => setErrorModal(false)}>OK</Button>
        </Modal>
        </div>
    )
}

export default EmptyTray;