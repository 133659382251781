import { configureStore } from "@reduxjs/toolkit";
import loginStepsSlice from "./login-steps-slice";
import alertSlice from "./alert-slice";
import userSlice from "./user-slice";
import ticketSlice from "./ticket-slice";
import layoutSlice from "./layout-slice";
import sampleSlice from "./sample-slice";
import loadingIndicatorSlice from "./loadingIndicator-slice";

export const store = configureStore({
  reducer: {
    loginStep: loginStepsSlice.reducer,
    alert: alertSlice.reducer,
    user: userSlice.reducer,
    ticket: ticketSlice.reducer,
    layout: layoutSlice.reducer,
    sample: sampleSlice.reducer,
    loadingIndicator: loadingIndicatorSlice.reducer
  },
});

export default store;
