import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { alertActions } from "../../store/alert-slice";
import './alert.scss';

const Alert = () => {
   const alerts = useSelector((state) => state.alert);
   const dispatch = useDispatch();

   useEffect(() => {
      if (alerts.messages?.length > 0) {
         setTimeout(() => {
            dispatch(alertActions.deleteAlert(alerts.messages[alerts.messages.length - 1].id));
         }, 6000);
      }
   }, [dispatch, alerts]);

   return (
      <>
         <div className="alert-wrapper">
            {alerts.messages?.map((alert) => (
               <div
                  className={`alert alert-${alert.type}`}
                  key={alert.id}
                  onClick={() => dispatch(alertActions.deleteAlert(alert.id))}
               >
                  <div>
                        <p> {alert.msg}</p>
                        {alert.type === "error" && !['/dashboard', '/login', '/locations'].includes(window.location.pathname) && <a href="/dashboard" className="alert-go-back">Go back to dashboard</a>}
                  </div>
                  <div
                     onClick={() => {
                        dispatch(alertActions.deleteAlert(alert.id));
                     }}
                  >
                  </div>
               </div>
            ))}
         </div>
      </>
   );
};

export default Alert;