import React, { useState } from "react";
import LanguageSwitch from './LanguageSwitch';
import {ReactComponent as BurgerMenu} from '../assets/images/burger-menu.svg';
import { useSelector} from "react-redux";
import { useTranslation } from "react-i18next";
import Drawer from "./Drawer";
import { roles } from "../assets/constants/roles";

const MobileHeader = () => {
    const data = useSelector(state => state.layout.layout);
    const [t] = useTranslation();
    const [openDrawer, setOpenDrawer] = useState(false);
    const activeMenu = useSelector(state => state.user.activeMenu);
    const routeName = useSelector(state => state.user.routeName);

    return(
        <header>
                <BurgerMenu onClick={() => setOpenDrawer(true)}/>
                <Drawer closeDrawer={() => setOpenDrawer(false)} isOpen={openDrawer}/>
                {data!== null? <p>{t(data)}</p> :<p>
                <label><span>{activeMenu === roles.driver? "Logistics" : activeMenu === roles.laboratory? "Lab" : "Center"}</span> Dashboard</label>
            <label className="route-name">{routeName}</label>
                </p>}  
                <LanguageSwitch/>
    </header>
    )
};

export default MobileHeader;