import React, { useEffect } from "react";
import {Html5Qrcode} from "html5-qrcode"
import './scanner.scss';
import {ReactComponent as Close} from '../../assets/images/icon-close.svg';
import { useState } from "react";
import Button from "../buttons/Button";
import '../../pages/Booking/booking.scss'
import { useTranslation } from "react-i18next";
import {ReactComponent as Check} from '../../assets/images/icon-check.svg';
import {ReactComponent as DotIcon} from '../../assets/images/icon-dot.svg';

const  Scanner = ({buttonText, buttonType, setButtonType, html5QrCode, getTicket, boxScanbar, setFinishScanning = () => {}}) => {
    const [ticket, setTicket] = useState("");
    const [t] = useTranslation();

    const CalcBorderWidth = () => {
        if(document.getElementById("qr-shaded-region")) {
            const width = (window.innerWidth - 400)/2;
            document.getElementById("qr-shaded-region").style.borderLeftWidth=`${width}px`
            document.getElementById("qr-shaded-region").style.borderRightWidth=`${width}px`
        }
    }

    const OpenScanner = () => {
        Html5Qrcode.getCameras().then(devices => {
            /**
             * devices would be an array of objects of type:
             * { id: "id", label: "label" }
             */
            if (devices && devices.length) {
                var cameraId = devices[0].id;
                // .. use this to start scanning.


                document.getElementById("scanner-wrapper").style.display = "flex";
                html5QrCode.start(
                    { facingMode: "environment" },
                    {
                        fps: 10,    // Optional, frame per seconds for qr code scanning
                        qrbox: { width: 980, height: 340 },  // Optional, if you want bounded box UI
                        aspectRatio: window.outerHeight / window.outerWidth // Fixes strange IOS 16.5 behvaiour
                    },

                    (decodedText, decodedResult) => {
                        getTicket(decodedText);
                        setTicket("");
                        buttonType !== "add_samples" &&  html5QrCode.stop().then((ignore) => {
                            document.getElementById("scanner-wrapper").style.display = "none";
                        }).catch((err) => {
                            // Stop failed, handle it.
                        });
                    },
                    (errorMessage) => {
                        // parse error, ignore it.
                    })
                    .catch((err) => {
                        // Start failed, handle it.
                    });
                setTimeout(() => {
                    CalcBorderWidth();
                }, 500);
            }
        }).catch(err => {
            // handle err
        });
    }

    const CloseScanner = () => {
        html5QrCode.stop().then((ignore) => {
            document.getElementById("scanner-wrapper").style.display = "none";
            buttonType === "add_samples" && setFinishScanning(true);
            setTicket("");
            setButtonType(null);
        }).catch((err) => {
            // Stop failed, handle it.
        });
    }

    const props = {
        style: {
            marginTop:"20px"
        },
        onClick:OpenScanner
    };

    useEffect(() => {
        window.addEventListener("resize", () => { CalcBorderWidth();})
    }, [])

    useEffect(() => {
        ["check", "scane_probe", "box_packing", "scan_delivery", "scan_empty_box", "quick_create_sample", "pack_samples", "add_samples", "take_subsample", "scan_subsample"].includes(buttonType) && OpenScanner();
    }, [buttonType])

    return (
        <>
            {buttonType === "green" &&  <Button {...props}>{buttonText}</Button>}

            <div id="scanner-wrapper">
                <div id="reader" className="scanner"/>
                <Close className="close" onClick={CloseScanner}/>
                <div className="scanner-list">
                    {boxScanbar?.title &&<h3>{boxScanbar?.title}</h3>}
                    {boxScanbar?.description &&<p className="scanner-list--description">{boxScanbar?.description}</p>}
                    {boxScanbar?.numberOfSamples}
                    {boxScanbar?.properties?.length > 0 && <ul className="test-details-list">
                        {boxScanbar?.properties?.map((prop, index) => <li key={index}>
                            <DotIcon/>
                            <p>
                                <span>{prop.label}</span>
                                <strong>{prop.value}</strong>
                            </p>
                        </li>)}
                    </ul>}
                    {boxScanbar?.listItems?.length > 0 && <ul className="scanner-list-items"> {boxScanbar?.listItems?.map((item, index) =>   <li key={index}><Check/>{item}</li>)} </ul>}
                </div>
                <div className="scanner-add--manually"
                     style={{marginBottom: buttonType === "add_samples" && '75px'}}
                >
                    <p>{t("scan.add_code")}</p>
                    <div className="scanner-input">
                        <input type="text" placeholder={t("scan.type_code")}
                               value={ticket}
                               onChange={e => setTicket(e.target.value)}/>
                        <button onClick={() => {
                            getTicket(ticket);
                            buttonType !== "add_samples" && CloseScanner();
                        }}>{t("scan.done")}</button>
                    </div>
                </div>
                {buttonType === "add_samples" && <Button
                    className="scanner-end-process"
                    onClick={CloseScanner}
                >{t("scan.end_scanning")}</Button>}
            </div>
        </>
    )

}

export default Scanner;