import config from "../config/api.config";
import ApiService from "./ApiService";

const AuthApi = {
  Login: async(data) => {
    return ApiService.Insert(`${config.apiUrl}/api/v1/login`,data, {withCredentials: true});
  },
  Logout: async() => {
    return ApiService.Insert(`${config.apiUrl}/api/v1/logout`,{});
  },
  GetUser: async() => {
    return ApiService.Insert(`${config.apiUrl}/api/v1/getUserInfo`,{});
  },
  GetLabDashboard: async(data) => {
    return ApiService.Insert(`${config.apiUrl}/api/v1/getLabDashboard`,data);
  }
};

export default AuthApi;